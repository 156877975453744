<template>
  <DetailedPageContainer>
    <div class="detailed__images" v-if="currentItem.images.length > 0">
      <r-row :wrap="false" class="px-4">
        <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 4 }"
               v-for="(img, index) in currentItem.images"
               :key="index">
          <r-image :url="img.replace('small', 'medium')"/>
        </r-col>
      </r-row>
    </div>

    <div class="detailed__title">
      <r-row class="px-4">
        <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 12 }">
          <div class="detailed__category mozzarella metropolis--text" v-if="category">
            {{ category }}
          </div>

          <h2 class="ricotta harakiri--text">
            {{ currentItem.title }}
          </h2>
        </r-col>
      </r-row>
    </div>

    <r-row class="px-4">
      <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 3 }">
        <div class="details" v-if="duration">
          <r-icon icon="clock" fill="rocky"/>
          <span class="zamorano">
            {{ duration }}
          </span>
        </div>

        <div class="details" v-if="distance">
          <r-icon icon="route" fill="rocky"/>
          <span class="zamorano">
            {{ distance }}
          </span>
        </div>

        <div class="details" v-if="city">
          <r-icon icon="geopoint" fill="rocky"/>
          <span class="zamorano">
            {{ city }}
          </span>
        </div>

        <QrCode v-if="currentItem.qr_code" :image="currentItem.qr_code" />
      </r-col>

      <r-col :cols="{ widest: 4, wide: 4, middle: 2, narrow: 3 }">
        <MoreText v-if="description" :text="description"/>
      </r-col>
    </r-row>

    <div class="detailed__additional-info" v-if="additions">
      <AdditionalInfo :data="additions"/>
    </div>

    <div class="detailed__more-items">
      <MoreItemsList :title="$t('more.more_routes')"
                     :category="currentItem.category.id"
                     type="routes"
                     :data-set="GET_ROUTES"/>
    </div>
  </DetailedPageContainer>
</template>

<script>
import {mapGetters} from "vuex";
import DetailedPageContainer from "@/components/Containers/DetailedPageContainer";
import AdditionalInfo from "@/components/DetailedViewParts/AdditionalInfo/AdditionalInfo";
import MoreText from "@/components/DetailedViewParts/MoreText/MoreText";
import QrCode from "@/components/DetailedViewParts/QrCode/QrCode";
import MoreItemsList from "@/components/DetailedViewParts/MoreItemsList/MoreItemsList";

export default {
  name: "DetailedRouteView",

  components: {
    DetailedPageContainer,
    MoreItemsList,
    QrCode,
    AdditionalInfo,
    MoreText,
  },

  computed: {
    ...mapGetters(['GET_ROUTES',]),

    currentItem() {
      return this.GET_ROUTES.filter(item => item.id == this.$route.params.id)[0];
    },

    duration() {
      if (this.currentItem?.duration_int) {
        return this.$duration(this.currentItem?.duration_int);
      }

      return null;
    },

    distance() {
      if (this.currentItem?.distance_int) {
        return this.$distance(this.currentItem?.distance_int);
      }

      return null;
    },

    description() {
      return this.currentItem?.description.replace(/<[^>]*>?/gm, '');
    },

    city() {
      return this.currentItem?.city.title;
    },

    category() {
      return this.currentItem?.category.title;
    },

    additions() {
      const arr = [];

      if (this.currentItem?.level?.title) {
        arr.push({
          id: 'level',
          key: this.$t('additions.difficulty'),
          value: this.currentItem?.level?.title
        });
      }

      if (this.currentItem?.season?.title) {
        arr.push({
          id: 'season',
          key: this.$t('additions.seasonality'),
          value: this.currentItem?.season?.title
        });
      }

      if (this.currentItem?.age_restriction?.title) {
        arr.push({
          id: 'age',
          key: this.$t('additions.age_restriction'),
          value: `${this.currentItem?.age_restriction?.title}+`
        });
      }

      return arr;
    }
  },
}
</script>

<style lang="scss">

</style>
